import router from 'next/router';
import Cookies from 'js-cookie';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import Session from 'supertokens-auth-react/recipe/session';

import { Permissions, Unauthorized } from '@sbio/interfaces';

import { Snack } from 'components/Snackbar/Snackbar.entity';
import { customSwitch } from 'utils/helpers';
import { apiUser } from '../../services/activateApi';
import ApiRes from '../../services/ApiRes';
import { getAllUsers } from '../admin/Admin.actions';
import { SET_SNACKBAR } from '../errors/Errors.reducer';
import { setModalContent } from '../modals/Modals.actions';
import { SET_IS_LOADING } from '../shared/Shared.reducers';
import { AppDispatch, AppState } from '../store';
import { IUser, UserReducer } from './User.model';
import {
  SET_2FA_VERIFICATION,
  SET_ACCOUNT_ACTIVATED,
  SET_ACTIVATION_USERID,
  SET_CURRENT_USER,
  SET_FORGOT_PASSWORD_FOUND,
  SET_ONBOARDED_USER,
  SET_ONBOARDING_STATUS,
  SET_PASSWORD_RESET,
  SET_RESEND_INVITATION,
  SET_USER_INFO_ACTIVATED,
} from './User.reducers';

interface ILogIn {
  email: string;
  password: string;
  captchaToken: string;
}

export const login = (entries: ILogIn): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.login({
      email: entries.email,
      password: entries.password,
      captchaToken: entries.captchaToken,
    });

    customSwitch(res.status, {
      200: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'auth.loginSuccess', 'success')));
        Cookies.set('lastRefreshSessionTokenDate', new Date().getTime().toString());
      },
      401: () => {
        if (res.error.includes(Unauthorized.CANNOT_LOGIN_ACCOUNT_NOT_CONFIRMED)) {
          dispatch(SET_RESEND_INVITATION(true));
        } else if (res.error.includes(Unauthorized.TWO_FA_REQUIRED)) {
          dispatch(SET_2FA_VERIFICATION(true));
        } else dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};

export const twoFAVerification = (email: string, code: string): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.twoFAVerification({
      email,
      code,
    });

    switch (res.status) {
      case 200:
        dispatch(SET_2FA_VERIFICATION(false));
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
        break;
    }
    dispatch(SET_IS_LOADING(false));
  };
};

export const resendInvitation = (email: string): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.resendInvitation({
      email,
    });

    switch (res.status) {
      case 204:
        dispatch(SET_SNACKBAR(new Snack(true, 'pages.resetPassword.checkEmail', 'success')));
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
        break;
    }
    dispatch(SET_RESEND_INVITATION(false));
    dispatch(SET_IS_LOADING(false));
  };
};
export const approveUser = (idUser: string): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.approveUser(idUser);

    switch (res.status) {
      case 204:
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.ACCOUNT_ACTIVATED', 'success')));
        dispatch(getAllUsers());
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
        break;
    }
    dispatch(SET_IS_LOADING(false));
  };
};

export const cancelInvitation = (idUser: string): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.cancelInvitation({
      idUser,
    });

    switch (res.status) {
      case 204:
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'success')));
        dispatch(getAllUsers());
        break;
      case 401:
        if (res.error.includes(Unauthorized.CANNOT_LOGIN_ACCOUNT_NOT_CONFIRMED)) {
          dispatch(SET_RESEND_INVITATION(true));
        }
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));

        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
        break;
    }
    dispatch(SET_IS_LOADING(false));
  };
};

export const getMe = (): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.getMe();

    customSwitch(res.status, {
      200: () => {
        dispatch(SET_CURRENT_USER(res.data));
      },

      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
        dispatch(logOut());
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};
export const logOut = () => {
  return async (dispatch: any) => {
    await Session.signOut();
    dispatch(SET_CURRENT_USER(null));
    await router.push('/');
  };
};

export const updatePreferredLanguage = (data: any) => {
  return async () => {
    await apiUser.updateLanguage(data);
  };
};

export const updatePassword = (id: string, data: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.updatePassword(id, data);

    customSwitch(res.status, {
      204: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'success')));
      },
      429: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'error')));
      },
      400: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'errors.auth.EMAIL_TOO_RECENTLY', 'error')));
      },
      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};

export const setActivateAccount = (password: string, token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.activateAccount(password, token);

    customSwitch(res.status, {
      200: () => {
        dispatch(SET_ACCOUNT_ACTIVATED(true));
        dispatch(SET_ACTIVATION_USERID(res.data._id));
        dispatch(SET_ONBOARDED_USER(res.data));
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.ACCOUNT_ACTIVATED', 'success')));
      },
      409: () => {
        dispatch(SET_ONBOARDING_STATUS('errors.selfHandledErrors.USER_ALREADY_ACTIVATED'));
      },
      default: () => {
        dispatch(SET_ONBOARDING_STATUS('errors.auth.invalidOrExpiredToken'));
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};

// TODO: handle the temp token thing from before refactoring?
export const setUserInfoActivated = (id: string, user: Partial<IUser>, token: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.putActivationData(id, { userData: user, token });

    customSwitch(res.status, {
      204: () => {
        dispatch(SET_USER_INFO_ACTIVATED(true));
        dispatch(SET_ONBOARDING_STATUS('apiMessages.users.USER_INFO_SAVED'));
      },
      401: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'Unauthorized', 'error')));
      },
      500: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'errors.general.failedToUpdateUser', 'error')));
      },
      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};

export const addPermissions = (id: string, data: Permissions[], closeModal: (data: null) => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.addPermission(id, data);

    switch (res.status) {
      case 200:
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'success')));
        dispatch(getAllUsers());
        closeModal(null);

        break;
      case 204:
        dispatch(SET_ONBOARDED_USER(res.data));
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
    }

    dispatch(SET_IS_LOADING(false));
  };
};

export const updateLabels = (id: string, data: string[], closeModal: (data: null) => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.updateLabels(id, data);

    switch (res.status) {
      case 204:
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'success')));
        dispatch(getAllUsers());
        closeModal(null);
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
    }

    dispatch(SET_IS_LOADING(false));
  };
};

export const removePermissions = (id: string, data: Permissions[], closeModal: (data: null) => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.removePermission(id, data);

    switch (res.status) {
      case 200:
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'success')));
        dispatch(getAllUsers());
        closeModal(null);

        break;
      case 204:
        dispatch(SET_ONBOARDED_USER(res.data));
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
    }
  };
};

export const setForgotPassword = (email: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res = await apiUser.setForgotPassword(email);

    console.log(res);
    switch (res.status) {
      case 200:
        dispatch(SET_FORGOT_PASSWORD_FOUND(true));
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.RESET_PASSWORD_EMAIL_SENT', 'success')));
        break;
      case 400:
        dispatch(SET_SNACKBAR(new Snack(true, 'errors.auth.EMAIL_TOO_RECENTLY', 'error')));
        break;
      case 404:
        dispatch(SET_FORGOT_PASSWORD_FOUND(false));
        dispatch(SET_SNACKBAR(new Snack(true, 'errors.auth.emailNotFound', 'error')));
        break;
      case 429:
        dispatch(SET_SNACKBAR(new Snack(true, 'errors.auth.emailNotFound', 'error')));
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
    }

    dispatch(SET_IS_LOADING(false));
  };
};

export const resetPassword = (token: string, newPassword: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.resetPassword(token, newPassword);

    switch (res.status) {
      case 204:
        dispatch(SET_PASSWORD_RESET(true));
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
    }

    dispatch(SET_IS_LOADING(false));
  };
};

export const inviteUser = (user: any, permissions: Permissions[], router: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.inviteUser(user, permissions);

    switch (res.status) {
      case 200:
        dispatch(SET_ONBOARDED_USER(res.data));
        dispatch(SET_IS_LOADING(false));

        return res;

      case 201:
        dispatch(SET_ONBOARDED_USER(res.data));
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.INVITATION_SENT', 'success')));
        dispatch(SET_IS_LOADING(false));
        router.push('/dashboard/users');
        return res;

      case 204:
        dispatch(SET_ONBOARDED_USER(res.data));
        return res;

      case 409:
        dispatch(SET_ONBOARDED_USER(res.data));

        dispatch(SET_SNACKBAR(new Snack(true, 'errors.conflict.TAKEN_EMAIL', 'error')));
        break;

      case 500:
        dispatch(SET_SNACKBAR(new Snack(true, "Couldn't send invitation", 'error')));

        return res;

      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
    }
    dispatch(SET_IS_LOADING(false));
  };
};
export const importMultipleUsers = (users: Partial<IUser>[], permissions) => {
  return async (dispatch: AppDispatch) => {
    const usersWithPermissions = users.map((user) => {
      return { user, permissions };
    });
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.importMultipleUsers(usersWithPermissions);
    switch (res.status) {
      case 201:
        dispatch(setModalContent(null));
        dispatch(
          SET_SNACKBAR(
            new Snack(
              true,
              res.data.conflicts
                ? 'apiMessages.users.MULTIPLE_INVITATION_SENT_CONFLICT'
                : 'apiMessages.users.MULTIPLE_INVITATION_SENT',
              'success',
            ),
          ),
        );
        dispatch(SET_IS_LOADING(false));
        return res;

      case 409:
        dispatch(SET_ONBOARDED_USER(res.data));
        dispatch(SET_SNACKBAR(new Snack(true, 'errors.conflict.TAKEN_EMAIL', 'error')));
        break;

      case 500:
        dispatch(SET_SNACKBAR(new Snack(true, "Couldn't send invitation", 'error')));
        dispatch(SET_IS_LOADING(false));
        return res;

      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
    }
    dispatch(SET_IS_LOADING(false));
    dispatch(setModalContent(null));
  };
};
export const updateUser = (id: string, data: Partial<UserReducer['userData']>, closeModal?: (data: null) => void) => {
  return async (dispatch: AppDispatch, getState) => {
    const {
      userReducer: { userData },
    } = getState();

    dispatch(SET_IS_LOADING(true));
    const res: ApiRes = await apiUser.updateUser(id, data);

    customSwitch(res.status, {
      204: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'success')));
        dispatch(getAllUsers());
        if (userData._id === id) {
          dispatch(getMe());
        }
        if (closeModal) closeModal(null);
      },
      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};

export const signUpUser = (data) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));
    const res: ApiRes = await apiUser.signUpUser(data);

    customSwitch(res.status, {
      200: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.SIGN_UP_REQUEST_SENT', 'success')));
        dispatch(setModalContent(null));
      },
      409: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.ADDRESS_EMAIL_ALREADY_USED', 'error')));
      },
      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};

export const updateMe = (data) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.updateMe(data);

    customSwitch(res.status, {
      204: () => {
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'success')));
        dispatch(getMe());
      },

      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};

export const banUser = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));

    const res: ApiRes = await apiUser.banUser(id);

    switch (res.status) {
      case 204:
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.users.USER_INFO_UPDATED', 'success')));
        dispatch(getAllUsers());
        break;
      default:
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
    }

    dispatch(SET_IS_LOADING(false));
  };
};

export const getPotentialEntityOwnersAutocomplete = (
  searchTerm: string,
  resource: 'documents' | 'elearning',
): ThunkAction<void, AppState, unknown, AnyAction> => {
  return async (dispatch: AppDispatch) => {
    const res: ApiRes = await apiUser.getPotentialEntityOwnersAutocomplete(searchTerm, resource);

    return customSwitch(res.status, {
      200: () => {
        return res?.data || [];
      },
      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });
  };
};
