"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.NotificationContent = exports.NotificationType = void 0;
var NotificationType;
exports.NotificationType = NotificationType;
(function(NotificationType) {
    NotificationType["ADMIN"] = 'admin';
    NotificationType["ENTITY"] = 'entity';
    NotificationType["NEWS"] = ' news';
})(NotificationType || (exports.NotificationType = NotificationType = {}));
var NotificationContent;
exports.NotificationContent = NotificationContent;
(function(NotificationContent) {
    NotificationContent["DOCUMENT_APPROVED"] = "DOCUMENT_APPROVED";
    NotificationContent["COURSE_APPROVED"] = "COURSE_APPROVED";
    NotificationContent["ELEARNING_APPROVED"] = "ELEARNING_APPROVED";
    NotificationContent["DOCUMENT_ARCHIVED"] = "DOCUMENT_ARCHIVED";
    NotificationContent["COURSE_ARCHIVED"] = "COURSE_ARCHIVED";
    NotificationContent["ELEARNING_ARCHIVED"] = "ELEARNING_ARCHIVED";
    NotificationContent["DOCUMENT_RESTORED"] = "DOCUMENT_RESTORED";
    NotificationContent["COURSE_RESTORED"] = "COURSE_RESTORED";
    NotificationContent["ELEARNING_RESTORED"] = "ELEARNING_RESTORED";
    NotificationContent["DOCUMENT_DELETED"] = "DOCUMENT_DELETED";
    NotificationContent["COURSE_DELETED"] = "COURSE_DELETED";
    NotificationContent["ELEARNING_DELETED"] = "ELEARNING_DELETED";
    NotificationContent["COURSE_FOLLOWED_DELETED"] = "COURSE_FOLLOWED_DELETED";
    NotificationContent["ELEARNING_FOLLOWED_DELETED"] = "ELEARNING_FOLLOWED_DELETED";
    NotificationContent["DISCUSSION_CREATED"] = "DISCUSSION_CREATED";
    NotificationContent["DISCUSSION_DELETED"] = "DISCUSSION_DELETED";
    NotificationContent["DISCUSSION_MESSAGE_ADDED"] = "DISCUSSION_MESSAGE_ADDED";
    NotificationContent["DISCUSSION_MESSAGE_DELETED"] = "DISCUSSION_MESSAGE_DELETED";
    NotificationContent["USER_BANNED"] = "USER_BANNED";
    NotificationContent["NEW_DISCUSSION"] = "NEW_DISCUSSION";
})(NotificationContent || (exports.NotificationContent = NotificationContent = {}));
