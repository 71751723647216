"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.DateFormat = exports.DocumentStatuses = exports.RefusalReasons = exports.Scopes = exports.TechnicsAndMethods = exports.ScientificDiscipline = exports.ApplicationDomains = exports.DocumentTypes = exports.Languages = void 0;
var Languages;
exports.Languages = Languages;
(function(Languages) {
    Languages["LANG_FRENCH"] = "LANG_FRENCH";
    Languages["LANG_ENGLISH"] = "LANG_ENGLISH";
    Languages["LANG_OTHER"] = "LANG_OTHER";
})(Languages || (exports.Languages = Languages = {}));
var DocumentTypes;
exports.DocumentTypes = DocumentTypes;
(function(DocumentTypes) {
    DocumentTypes["DOCTYPE_PROFESSIONAL_AGREEMENTS"] = "DOCTYPE_PROFESSIONAL_AGREEMENTS";
    DocumentTypes["DOCTYPE_JURISPRUDENCE"] = "DOCTYPE_JURISPRUDENCE";
    DocumentTypes["DOCTYPE_STANDARDS"] = "DOCTYPE_STANDARDS";
    DocumentTypes["DOCTYPE_BEST_PRACTICES"] = "DOCTYPE_BEST_PRACTICES";
    DocumentTypes["DOCTYPE_SCIENTIFIC_PUBLICATIONS"] = "DOCTYPE_SCIENTIFIC_PUBLICATIONS";
    DocumentTypes["DOCTYPE_BROCHURE"] = "DOCTYPE_BROCHURE";
    DocumentTypes["DOCTYPE_FOLDER"] = "DOCTYPE_FOLDER";
    DocumentTypes["DOCTYPE_AGREEMENTS"] = "DOCTYPE_AGREEMENTS";
    DocumentTypes["DOCTYPE_OTHER"] = "DOCTYPE_OTHER";
})(DocumentTypes || (exports.DocumentTypes = DocumentTypes = {}));
var ApplicationDomains;
exports.ApplicationDomains = ApplicationDomains;
(function(ApplicationDomains) {
    ApplicationDomains["CAT_AD_AGRICULTURE"] = "CAT_AD_AGRICULTURE";
    ApplicationDomains["CAT_AD_FOOD_PROCESSING"] = "CAT_AD_FOOD_PROCESSING";
    ApplicationDomains["CAT_AD_AIR"] = "CAT_AD_AIR";
    ApplicationDomains["CAT_AD_BIOTECHNOLOGY"] = "CAT_AD_BIOTECHNOLOGY";
    ApplicationDomains["CAT_AD_GREEN_CHEMISTRY"] = "CAT_AD_GREEN_CHEMISTRY";
    ApplicationDomains["CAT_AD_CONSULTING_CORPORATE_SERVICES"] = "CAT_AD_CONSULTING_CORPORATE_SERVICES";
    ApplicationDomains["CAT_AD_COSMETICS"] = "CAT_AD_COSMETICS";
    ApplicationDomains["CAT_AD_WASTE"] = "CAT_AD_WASTE";
    ApplicationDomains["CAT_AD_BREEDING_FARMING"] = "CAT_AD_BREEDING_FARMING";
    ApplicationDomains["CAT_AD_ENVIRONMENT"] = "CAT_AD_ENVIRONMENT";
    ApplicationDomains["CAT_AD_EDUCATION"] = "CAT_AD_EDUCATION";
    ApplicationDomains["CAT_AD_HYDROLOGY"] = "CAT_AD_HYDROLOGY";
    ApplicationDomains["CAT_AD_PHARMACEUTICAL"] = "CAT_AD_PHARMACEUTICAL";
    ApplicationDomains["CAT_AD_PHYTOSANITARY"] = "CAT_AD_PHYTOSANITARY";
    ApplicationDomains["CAT_AD_HEALTH_HYGIENE"] = "CAT_AD_HEALTH_HYGIENE";
    ApplicationDomains["CAT_AD_GEOLOGY"] = "CAT_AD_GEOLOGY";
    ApplicationDomains["CAT_AD_TRANSPORT"] = "CAT_AD_TRANSPORT";
    ApplicationDomains["CAT_AD_PACKAGING"] = "CAT_AD_PACKAGING";
    ApplicationDomains["CAT_AD_CIVIL_PROTECTION"] = "CAT_AD_CIVIL_PROTECTION";
})(ApplicationDomains || (exports.ApplicationDomains = ApplicationDomains = {}));
var ScientificDiscipline;
exports.ScientificDiscipline = ScientificDiscipline;
(function(ScientificDiscipline) {
    ScientificDiscipline["CAT_SD_BIOCHEMISTRY"] = "CAT_SD_BIOCHEMISTRY";
    ScientificDiscipline["CAT_SD_BIOLOGY"] = "CAT_SD_BIOLOGY";
    ScientificDiscipline["CAT_SD_BIOTECHNOLOGY"] = "CAT_SD_BIOTECHNOLOGY";
    ScientificDiscipline["CAT_SD_CHEMISTRY"] = "CAT_SD_CHEMISTRY";
    ScientificDiscipline["CAT_SD_ENVIRONMENT"] = "CAT_SD_ENVIRONMENT";
    ScientificDiscipline["CAT_SD_GENETICS"] = "CAT_SD_GENETICS";
    ScientificDiscipline["CAT_SD_MATHEMATICS_STATISTICS"] = "CAT_SD_MATHEMATICS_STATISTICS";
    ScientificDiscipline["CAT_SD_MEDECINE"] = "CAT_SD_MEDECINE";
    ScientificDiscipline["CAT_SD_VETERINARY"] = "CAT_SD_VETERINARY";
    ScientificDiscipline["CAT_SD_MICROBIOLOGY"] = "CAT_SD_MICROBIOLOGY";
    ScientificDiscipline["CAT_SD_TOXICOLOGY"] = "CAT_SD_TOXICOLOGY";
    ScientificDiscipline["CAT_SD_NUCLEAR"] = "CAT_SD_NUCLEAR";
    ScientificDiscipline["CAT_SD_ENERGY"] = "CAT_SD_ENERGY";
    ScientificDiscipline["CAT_SD_BOTANICAL"] = "CAT_SD_BOTANICAL";
})(ScientificDiscipline || (exports.ScientificDiscipline = ScientificDiscipline = {}));
var TechnicsAndMethods;
exports.TechnicsAndMethods = TechnicsAndMethods;
(function(TechnicsAndMethods) {
    TechnicsAndMethods["CAT_TM_PROCUREMENT"] = "CAT_TM_PROCUREMENT";
    TechnicsAndMethods["CAT_TM_CENTER_BIOLOGICAL_RESOURCES"] = "CAT_TM_CENTER_BIOLOGICAL_RESOURCES";
    TechnicsAndMethods["CAT_TM_CONTROL_AUDIT_INSPECTION"] = "CAT_TM_CONTROL_AUDIT_INSPECTION";
    TechnicsAndMethods["CAT_TM_MAINTENANCE"] = "CAT_TM_MAINTENANCE";
    TechnicsAndMethods["CAT_TM_INFRASTRUCTURE_FACILITIES"] = "CAT_TM_INFRASTRUCTURE_FACILITIES";
    TechnicsAndMethods["CAT_TM_MANUFACTURING_MANAGEMENT"] = "CAT_TM_MANUFACTURING_MANAGEMENT";
    TechnicsAndMethods["CAT_TM_RISK_MANAGEMENT"] = "CAT_TM_RISK_MANAGEMENT";
    TechnicsAndMethods["CAT_TM_QHSE_MANAGEMENT"] = "CAT_TM_QHSE_MANAGEMENT";
    TechnicsAndMethods["CAT_TM_MEASURES_INSTRUMENTATION"] = "CAT_TM_MEASURES_INSTRUMENTATION";
    TechnicsAndMethods["CAT_TM_MANAGEMENT_PROCESS"] = "CAT_TM_MANAGEMENT_PROCESS";
    TechnicsAndMethods["CAT_TM_MEDICAL_PRESCRIPTION"] = "CAT_TM_MEDICAL_PRESCRIPTION";
    TechnicsAndMethods["CAT_TM_R_D"] = "CAT_TM_R_D";
    TechnicsAndMethods["CAT_TM_AWARENESS_EDUCATION"] = "CAT_TM_AWARENESS_EDUCATION";
    TechnicsAndMethods["CAT_TM_STORAGE_HANDLING"] = "CAT_TM_STORAGE_HANDLING";
    TechnicsAndMethods["CAT_TM_WASTE_TREATMENT"] = "CAT_TM_WASTE_TREATMENT";
    TechnicsAndMethods["CAT_TM_TRANSPORTATION_LOGISTICS"] = "CAT_TM_TRANSPORTATION_LOGISTICS";
    TechnicsAndMethods["CAT_TM_AGRICULTURAL_TECHNIQUES"] = "CAT_TM_AGRICULTURAL_TECHNIQUES";
})(TechnicsAndMethods || (exports.TechnicsAndMethods = TechnicsAndMethods = {}));
var Scopes;
exports.Scopes = Scopes;
(function(Scopes) {
    Scopes["SCOPE_FRANCE"] = "SCOPE_FRANCE";
    Scopes["SCOPE_EU"] = "SCOPE_EU";
    Scopes["SCOPE_WORLD"] = "SCOPE_WORLD";
    Scopes["SCOPE_OTHER"] = "SCOPE_OTHER";
})(Scopes || (exports.Scopes = Scopes = {}));
var RefusalReasons;
exports.RefusalReasons = RefusalReasons;
(function(RefusalReasons) {
    RefusalReasons["IRRELEVANT"] = "IRRELEVANT";
    RefusalReasons["MISTAKE"] = "MISTAKE";
    RefusalReasons["OTHER"] = "OTHER";
    RefusalReasons["DELETED"] = "DELETED";
})(RefusalReasons || (exports.RefusalReasons = RefusalReasons = {}));
var DocumentStatuses;
exports.DocumentStatuses = DocumentStatuses;
(function(DocumentStatuses) {
    DocumentStatuses["ACTIVE"] = 'active';
    DocumentStatuses["PENDING"] = 'pending';
    DocumentStatuses["ARCHIVED"] = 'archived';
    DocumentStatuses["REFUSED"] = 'refused';
    DocumentStatuses["PENDING_RESTORATION"] = 'pending_restoration';
})(DocumentStatuses || (exports.DocumentStatuses = DocumentStatuses = {}));
var DateFormat;
exports.DateFormat = DateFormat;
(function(DateFormat) {
    DateFormat["ONLY_YEAR"] = 'yyyy';
    DateFormat["ONLY_MONTH_YEAR"] = 'yyyy-MM';
    DateFormat["FULL_DATE"] = 'yyyy-MM-dd';
})(DateFormat || (exports.DateFormat = DateFormat = {}));
